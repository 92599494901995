import axios from 'axios'

export default {
    namespaced: true,

    state: {
        authenticated: sessionStorage.authenticated || false,
        user: {employee_id: null, employee_name: null},
        admin: sessionStorage.admin || false,
        userName: sessionStorage.userName || null,    
        userRecord: null,       
        overlay: false,
        dialog: false,
        loginErrors: '',
        resetPassword: false,
        resetPasswordShow: false,
        newPassword: '',
        displayPasswordChanged: false,        
        step: 1,
        employeeId: sessionStorage.employeeId || null,
        employeeSignedIn: '',
        employeeInitials: '',
        employeePost: '',
        validUserName: false,
        expand: true,
        adminPermissions: sessionStorage.getItem('adminPermissions') || null            
    },

    getters: {
        getAuthenticated (state) {
            return state.authenticated
        },

        getUser (state) {
            return state.user
        },     

        getUserRecord(state){
            return state.userRecord
        },

        getOverlay(state){
            return state.overlay
        },       

        getDialog(state){
            return state.dialog
        },

        getLoginErrors(state){
            return state.loginErrors
        },

        getResetPassword(state){
            return state.resetPassword
        },

        getNewPassword(state){
            return state.newPassword
        },

        getDisplayPasswordChanged(state){
            return state.displayPasswordChanged
        },        

        getStep(state){
            return state.step
        },
        
        getEmployeeId(state){
            return state.employeeId
        },

        getEmployeeSignedIn(state){
            return state.employeeSignedIn
        },

        getEmployeeInitials(state){
            return state.employeeInitials
        },
        
        getValidUserName(state){
            return state.validUserName
        },

        getUserName(state){
            return state.userName
        },

        getResetPasswordShow(state){
            return state.resetPasswordShow
        },
        getExpand(state){
            return state.expand
        },

        getAdmin (state) {
            return state.admin
        },

        getEmployeePost (state) {
            return state.employeePost
        },

        getAdminPermissions (state) {
            return state.adminPermissions
        }
    },

    mutations: {
        setAuthenticated (state, value) {
            state.authenticated = value
        },

        setUser (state, value) {
            state.user = value
        },

        setUserId (state, value){
            state.user.student_id = value;
        },

        setUserRecord (state, value){
            state.userRecord = value
        },

        setOverlay (state, value){
            state.overlay = value
        },
        
        setDialog(state, value){
            state.dialog = value
        },

        setLoginErrors(state, value){
            state.loginErrors = value
        },

        setResetPassword(state, value){
            state.resetPassword = value
        },

        setNewPassword(state, value){
            state.newPassword = value
        },

        setDisplayPasswordChanged(state, value){
            state.displayPasswordChanged = value
        },        

        setStep(state, value){
            state.step = value
        },

        setEmployeeId(state, value){
            state.employeeId = value
        },

        setEmployeeSignedIn(state, value){
            state.employeeSignedIn = value
        },

        setEmployeeInitials(state, value){
            state.employeeInitials = value
        },

        setValidUserName(state, value){
            state.validUserName = value
        },

        setUserName(state, value){
            state.userName = value
        },

        setExpand(state, value){
            state.expand = value
        },

        setAdmin (state, value) {
            state.admin = value
        },

        setEmployeePost (state, value) {
            state.employeePost = value
        },

        setAdminPermissions (state, value) {
            state.adminPermissions = value
        }
        
    },

    actions: {
        async signIn ({ commit, dispatch }, credentials){            
            commit('setOverlay', true);           
           
            await axios.get('/sanctum/csrf-cookie')
            try {
                let response = await axios.post('/login', credentials);
                console.log(response)
                if(response.status === 200){
                    commit('setAuthenticated', true);
                    commit('setUser', credentials);                    
                    await dispatch('student');  
                }
                else{
                    commit('setLoginErrors', "Error occured (Response status not set)");
                    commit('setAuthenticated', false);
                    commit('setOverlay', false); 
                }
            } catch (error) {                
                commit('setLoginErrors', error.response.data.message);
                commit('setAuthenticated', false);
                commit('setOverlay', false);  
            } 
        },

        async adminSignIn({ commit, dispatch }, credentials){
            
            try {
                await axios.get('/sanctum/csrf-cookie');
                const { data } = await axios.post('/api/admin-login', credentials);
                sessionStorage.authenticated = data.id
                sessionStorage.admin = data.id ;
                commit('setAuthenticated', true);
                commit('setLoginErrors', '')
                commit('setUser', credentials);
                
                if(data.id){
                    sessionStorage.setItem('userName', data.name);
                    commit('setUserName', data.name);
                    commit('setAdmin', data.id );
                }
            } catch (error) {
                dispatch('serverError', error)
            }
                       
              
        },

        async employeeSignIn({ commit, dispatch }, credentials){
            let response = null;
            commit('setOverlay', true);
            try {
                await axios.get('/sanctum/csrf-cookie');
                response = await axios.post('/api/employee-login', credentials);
                console.log(response.data);
                sessionStorage.authenticated = response.data;
                sessionStorage.employeeId = response.data[0].employee_id;
                commit('setAuthenticated', true);
                commit('setEmployeeId', response.data[0].employee_id);
                commit('setLoginErrors', "");                
            } catch (error) {
                dispatch('serverError', error);
                commit('setOverlay', false);
            }            
            
        },

        async employee({ getters, commit }){
            let url = '/api/employee/' + getters.getEmployeeId;
            const { data } = await axios.get(url);
            let name = '';
            let initials = '';
            let employeePostId = '';
            if(data.length > 0){
                const [{ first_name: firstName, last_name: lastName, post_id: postId } ] = data;
                name = firstName + ' ' + lastName;
                initials = firstName[0] + lastName[0];
                employeePostId = postId;
            }            
            commit('setEmployeeSignedIn', name);
            commit('setEmployeeInitials', initials);
            commit('setEmployeePost', employeePostId);            
        },        

        async user({ getters }){           
            console.log("getting user information...");
            let url = '/api/user-employee/' + getters.getUserName;            
            return axios.get(url);           
        },

        async changePassword({ getters }){            
            let url = '/api/employee-change-password';
            return await axios.post(url, {
                "name" : getters.getUserName,
                "password" : getters.getNewPassword,
            })            
        },        

        async signOut({ commit }){
            let response = null;
            try {
                response = await axios.post('/logout');
                console.log(response.status);                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);                
            }
            commit('setAuthenticated', false);                       
            sessionStorage.clear(); 
        },        
        
       checkSession({dispatch}){           
           console.log(`Cookie Set: ${dispatch('cookieSet', 'ans_api_session')}`);
       },      

       async changeResetPassword({ commit, getters }, resetPassword){
            commit('setOverlay', true);
            await axios.post('/api/change-reset-password',{
                "student_id" : getters.getUserId,
                "reset_password" : resetPassword,
            });
            commit('setResetPassword', resetPassword);
            commit('setDisplayPasswordChanged', true);
            commit('setOverlay', false);
       },

       cookieSet(cookieName){
           let name = cookieName + "=";
           let decodedCookie = decodeURIComponent(document.cookie);
           let cookies = decodedCookie.split(';');
           for(var i = 0; i < cookies.length; i++){
               var c = cookies[i];
               while(c.charAt(0) == ' '){
                   c = c.substring(1);
               }
               if(c.indexOf(name) == 0){
                    return true;
               }
           }
           return false;
       },

       serverError({ commit }, err ){
            console.log(err.response)
            //var hasErrors = Object.prototype.hasOwnProperty.call(err.response, 'errors');                                              
            if( err.response){
                commit('setLoginErrors', err.response.data.errors.message[0]);
            }
            else{
                commit('setLoginErrors', err.response.statusText);
            }                    
            commit('setAuthenticated', false);
            commit('setOverlay', false);
       },

       getAdminPermissions ({ getters }) {
        const url = '/api/admin-permissions';
        
        return axios.get(url, { params: {
            user_admin_id: getters.getAdmin
        }})
       }
    }
}