<template>
    <v-list
        dense
        nav
    >
        <v-list-item-group
            v-model="selectedItem"
            color="primary"
        > 
            <v-list-item
                v-for="(item, i) in itemsPermissions"
                :key="i"                                      
                @click="display(item)"                                    
            >
                <v-list-item-icon v-if="item.visible">
                    <v-icon>
                        {{ item.icon }}
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content v-if="item.visible">
                    <v-list-item-title>
                        {{ item.text }}
                    </v-list-item-title>
                </v-list-item-content>

            </v-list-item>

        </v-list-item-group>                
    </v-list>                 
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    name: 'AdminNavigation',

    created: function () {
        this.initialize();
    },

    computed: {
        ...mapGetters({
            menuOptions: 'admin/getMenuOptions',
            adminPermissions: 'auth/getAdminPermissions',
        })
    },

    data: () => ({      
        
        items: [
            { text: 'Dashboard', icon: 'mdi-view-dashboard', submenu: false, menu: 'dashboard', visible: true },                     
            { text: 'Teachers', icon: 'mdi-account-group', submenu: false, menu: 'employees', visible: true },            
            { text: 'Students', icon: 'mdi-account-group', submenu: false, menu: 'students', visible: true },            
            { text: 'Subjects', icon: 'mdi-library', submenu: false, menu: 'studentSubjects', visible: true },            
            { text: 'Post Term Reports', icon: 'mdi-post', submenu: false, menu: 'postReports', visible: true },            
            { text: 'Edit\\View Term Details', icon: 'mdi-file-document-edit-outline', submenu: false, menu: 'termReports', visible: true },
            { text: 'Enter Marks', icon: 'mdi-keyboard', submenu: false, menu: 'enterMarks', visible: true },
            { text: 'Reports', icon: 'mdi-file-cabinet', submenu: true, menu: 'reports', visible: true},
            { text: 'Registration', icon: 'mdi-calendar-clock', submenu: false, menu: 'registration', visible: true},
            // { text: 'Mark Book', icon: 'mdi-notebook', submenu: true, menu: 'markBook', visible: true},
            //{ text: 'Export Data', icon: 'mdi-database-export-outline', submenu: false, menu: 'exportData', visible: true },            
            //{ text: 'Import Students', icon: 'mdi-cloud-upload-outline', submenu: false, menu: 'importData',visible: false },
            { text: 'Student Attendance', icon: 'mdi-bell', submenu: false, menu: 'attendanceStudent', visible: true},
        ],       
        selectedItem: 0,
        itemsPermissions: [],       
    }),
    
    methods: {
        ...mapActions({
            registrationData: 'admin/downloadRegistrationData',
            getFormClasses: 'termReports/getFormClasses',
            getCodedCommentsList: 'termReports/getCodedCommentsList',
            currentPeriod: 'termReports/getCurrentAcademicPeriod', 
        }),

        ...mapMutations({
            setMenuOptions: 'admin/setMenuOptions',
            editViewTermReportsOverlay: 'termReports/setEditViewTermReportsOverlay',
            editViewTermReportsLoading: 'termReports/setEditViewTermReportsLoading',
            setFormClassesList: 'termReports/setFormClassesList',
            setEditTermReports: 'admin/setEditTermReports',
        }),

        async initialize () {
            console.log("initializing navigation")
            let menuOptions = {};
            const permissions = JSON.parse(this.adminPermissions);
            try {
                await this.currentPeriod();
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            
            this.items.forEach( value => {
                menuOptions[value.menu] = false;                

                permissions.forEach( permission => {                    
                    if(permission.menu == value.menu){
                        this.itemsPermissions.push(value);
                    }

                    if(permission.action == 'editTermReports') this.setEditTermReports(true);
                })   
            })

            if(this.itemsPermissions.length > 0){
               
                menuOptions[this.itemsPermissions[0].menu] = true;
            } 
            this.setMenuOptions(menuOptions);            
        },

        display(item){
            Object.keys(this.menuOptions).forEach(key => {
                this.menuOptions[key] = false;
            })
            this.menuOptions[item.menu] = true;
        },

        downloadExcel () {
            this.registrationData()
            .then(response => {                
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download','Registration Data.xlsx')
                document.body.appendChild(link)                
                link.click()
                this.setExportData(false);                
            })
        },
        
    }
}
</script>

<style scoped>
    ::v-deep .v-list-item__title{
        white-space: pre-wrap;
    }
</style>