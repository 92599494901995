<template>
    <tr @click="recordSelected">
        <td style="padding: 0 6px">{{ markRecord.count }}</td>
        
        <td class="pt-1" style="padding: 0 6px">
            {{ markRecord.name }}
        </td> 

        
        <td style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.course_mark"
                outlined
                dense
                hide-details
                type="number"
                @blur="recordChanged"
                :filled="disableCourseMark"
                :disabled="disableCourseMark"
            ></v-text-field>
        </td>

         <td style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.exam_mark"
                outlined
                dense
                hide-details
                type="number"
                @blur="recordChanged"
                :filled="disableExamMark"
                :disabled="disableExamMark"
            ></v-text-field>
        </td>
        
        <td style="padding: 0 6px">
           <v-select
                v-model="codedComment"                
                :items="comments1"
                item-value="id"
                outlined
                dense
                hide-details
                return-object                
                @change="recordChanged"                
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template> 

            </v-select>
        </td>

        <td style="padding: 0 6px"> 
            <v-select
                v-model="codedComment1"                
                :items="comments2"
                item-value="id"
                outlined
                dense
                hide-details
                return-object                
                @change="recordChanged"                
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template>
            </v-select>
        </td>

        <td style="padding: 0 6px">
            <v-tooltip 
                bottom
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="green"                        
                        v-bind="attrs"
                        v-on="on"
                        v-show="saved"
                        class="mr-2"
                    >
                        mdi-check-all
                    </v-icon>
                </template>    
                <span>Saved</span>                
            </v-tooltip>
            <v-tooltip 
                top
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="red"                       
                        v-bind="attrs"
                        v-on="on"
                        v-show="error"
                    >
                        mdi-alert-circle
                    </v-icon>
                </template>    
                <span>Error</span>               
            </v-tooltip>
            <v-progress-circular
                indeterminate
                color="primary"
                v-show="saving"
                size="16"
                width="3"
            ></v-progress-circular>
        </td>
    </tr>

    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props:{
        studentRecord: Object,
        formLevel: Number,        
    },
    
    created () {
        this.initialize();
    },

    watch: {        
        codedComments: {
            deep: true,
            handler(value){
                if(value.length != 0){
                    this.setComments();
                }
            }
        },
        codedComment: {
            handler(value){
                //console.log(value);
                this.markRecord.coded_comment = value.id;
            }
        },
        codedComment1: {
            handler(value){
                //console.log(value);
                this.markRecord.coded_comment_1 = value.id;
            }
        },

    },       
    data() {
        return {
            comments1: [],
            comments2: [],            
            markRecord: {},
            codedComment: { id: null, comment: null },
            codedComment1: { id: null, comment: null },
            saved: false,
            saving: false,
            error: false,
            disableCodedComment: false,
            disableCodedComment1: false,
            disableExamMark: false,
            disableCourseMark: false,
        }
    },
    computed: {
        ...mapGetters({
            codedComments: 'termReports/getCodedComments',
            employeeId: 'auth/getEmployeeId',
            marksEntered: 'termReports/getSubjectStudentsMarksEntered',
            lessonSelected: 'termReports/getLessonSelected',
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',
        }),
       
       
    },
    methods : {
        ...mapMutations({
            setUpdatedRecord: 'termReports/setUpdatedTable2Record',
            setStudentSelected: 'termReports/setMarkSheetStudentSelected',
            setMarkSheetEntered: 'termReports/setSubjectStudentsMarksEntered',
            setSelectedTable2RecordIndex: 'termReports/setSelectedTable2RecordIndex',
        }),

        ...mapActions({
            save: 'termReports/saveTable2Record',
        }),
        
        initialize(){
            this.markRecord = this.studentRecord;
            // console.log(this.markRecord);
            this.setStudentSelected(null);
            this.savedDataCheck();
            this.setComments();
            this.setDisabledFields();
        },

        async recordChanged(){
            console.log("updating record");
            //console.log('this record has changed ...');            
            //console.log(this.recordBlankCheck());
            //console.log(this.markRecord);
            if(!this.recordBlankCheck()){
                console.log('record not blank');
                //console.log('record changed...');            
                this.saving = true;
                this.saved = false;
                this.error = false;

                this.setUpdatedRecord(
                    {
                        "student_id" : this.markRecord.student_id,
                        "year" : this.markRecord.year,
                        "term" : this.markRecord.term,
                        "subject_id" : this.markRecord.subject_id,
                        "exam_mark" : this.markRecord.exam_mark,
                        "course_mark" : this.markRecord.course_mark,                   
                        "coded_comment" : this.codedComment.id,
                        "coded_comment_1" : this.codedComment1.id,
                        "employee_id" : this.employeeId,                   
                    }
                );

                try {
                    await this.save();
                    this.saving = false;
                    this.saved = true;
                    this.error = false;
                } catch (error) {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                    this.saving = false;
                    this.error = true;
                    this.saved = false;
                }
                
               
            }
            else{
                //console.log('record blank');
            }                  
        },

        recordBlankCheck(){
            //console.log('record blank check..');
            let record = [];
            let blank = true;                     
            record.push(this.markRecord.course_mark);
            record.push(this.markRecord.exam_mark);            
            record.push(this.codedComment.id);
            record.push(this.codedComment1.id);
            //console.log(record);            
            record.forEach(element => {
                if(element) blank = false;
            })
            //console.log(blank);            
            return blank;
        },

        savedDataCheck(){
            if(!this.recordBlankCheck()) this.saved = true;
        },

        recordSelected(){
            //console.log('marksheetrecord: ', this.markRecord);
            //console.log("record selected");
            let records = this.table2Records;
            //console.log(records);
            let entered = 0;
            records.forEach(record => {
                if(record.course_mark || record.exam_mark || record.conduct || record.application || record.preparedness || record.coded_comment || record.coded_comment_1){
                    entered++;
                }
            })
            console.log(entered);
            //console.log(records);          
            this.setStudentSelected(this.markRecord.name);
        }, 

        setComments(){ 
            this.comments1.push({id: null, comment: null});  
            this.comments2.push({id: null, comment: null});
            //console.log(this.codedComments);  
            this.codedComments.forEach(record =>{
                this.comments1.push({ id: record.id, comment: record.detail });
                this.comments2.push({ id: record.id, comment: record.detail });
            });           
            
            if(this.studentRecord.coded_comment){
                this.codedComment = { id: this.studentRecord.coded_comment };
            } 
            if(this.studentRecord.coded_comment_1){
                this.codedComment1 = { id: this.studentRecord.coded_comment_1 };
            }
            
        },

        setDisabledFields(){           
            
            if(this.currentTerm == 1 && this.lessonSelected.formLevel == 6){
                this.disableExamMark = true;
            }

            if(this.currentTerm == 2 && 
                (
                    this.lessonSelected.formLevel == 1 ||
                    this.lessonSelected.formLevel == 2 ||
                    this.lessonSelected.formLevel == 4
                )
            ){
                this.disableExamMark = true;            
            }

            if(this.currentTerm == 2 && (
                this.lessonSelected.formLevel == 5 ||
                this.lessonSelected.formLevel == 6
            )){
                this.disableCourseMark = true;
            }

            if(this.currentTerm == 3 && this.lessonSelected.formLevel == 3){
                this.disableExamMark = true;
            }

        },
    }
}
</script>

<style scoped> 
    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 6px;
    }

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
    }
</style>