<template>
    <v-col class="pr-0 pb-0 mb-0">
        <v-row class="mr-0">
            <v-card
                flat
                :width="width"
                align="center"
                justify="center"
            >
                <span 
                    class="mr-3 text-subtitle-1 green--text"
                    style=" font-weight:bold"                                         
                > {{ studentName }}</span>                                 
                <span 
                    class="text-subtitle-1 green--text"
                    style=" font-weight:bold" 
                >{{ classId }}</span>
            </v-card>
        </v-row>

        <v-row class="mr-0">
            <v-card                                    
                :width="width"
                flat
            >
                <v-row
                    class="px-3"
                >
                    <v-col
                        align="center"
                        justify="center"
                        v-for="item in academicPeriod"
                        :key="item.title"
                        class="pa-0"
                    >
                        <div 
                            class="text-caption blue--text"
                            style=" font-weight:bold" 
                        >{{item.title}}</div>
                        <div 
                            class="text-caption green--text"
                            style=" font-weight:bold" 
                        >{{item.value}}</div>
                    </v-col>
                </v-row>
            </v-card>                                 
        </v-row>

        <v-row class="mr-0">
            <v-card
                :width="width"
                flat
            >
                <div>
                    <v-list class="py-0" dense>
                        <v-list-item
                            v-for="item in recordSummary"
                            :key="item.title"
                            class="pr-0"
                        >
                            <v-list-item-content class="py-0">
                                <v-list-item-title 
                                    class="text-caption"
                                >
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-card                               
                                width="150"                                                                                                       
                                flat
                            >
                                <v-text-field
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled" 
                                    @blur="updateRecord"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="studentRecord[item.model]"
                                    height="20"
                                    class="text-caption pa-0"
                                ></v-text-field>
                                
                            </v-card> 
                        </v-list-item>
                    </v-list>
                </div>                                     
            </v-card>
        </v-row>

        <v-card
            flat
            :width="width"
        >
            <v-row class="mx-0">
                <v-col class="pb-0 pl-0">
                    <v-textarea                                       
                        label="Form Teacher's Remarks"                            
                        persistent-hint
                        placeholder=" "
                        dense
                        rows="3"
                        outlined
                        :value="value"                        
                        counter                                            
                        class="text-caption"
                        :rules="rules"                      
                        no-resize
                        maxlength="255"
                        v-model="studentRecord.comments"                            
                        @blur="saveTeacherComment"
                        @click="openBottomSheet('comments')"
                        :readonly="disabled"
                        :filled="disabled"
                    >                        
                    </v-textarea>

                </v-col>
                
            </v-row>
        </v-card>

        <v-row>
            <v-col class="pa-0 flex-grow-0">
                <v-card
                    flat
                    :width="width"
                >
                    <v-row 
                        class="pl-3 ml-0 mb-0"
                    >
                        
                        <v-col cols="4" class="py-0"></v-col>

                        <v-col cols="4" class="py-0">
                            <v-card 
                                
                                flat
                                class=""
                                style="font-size:0.55rem; "
                            >
                                Grade
                            </v-card>
                        </v-col>

                        <v-col cols="4" class="py-0">    
                            <v-card 
                            
                                flat 
                                style="font-size:0.55rem;"                           
                            >
                                Comment
                            </v-card> 
                        </v-col>
                    </v-row>

                    <v-row class="pl-3 ml-0 mb-2">
                        <v-col 
                            cols="4" class="py-0  px-0 d-flex justify-center align-center"
                            style="font-size:0.7rem"
                        >
                            <v-card 
                                flat 
                                style="font-size:0.7rem"
                                class="mx-2"
                                
                            >
                                Religious Knowledge
                            </v-card>                            
                        </v-col>

                        <v-col cols="4" class="py-0">
                            <v-card 
                                flat
                            >
                                <v-select
                                    v-model="studentRecord.mcourse"
                                    outlined
                                    dense
                                    hide-details
                                    style="font-size:0.7rem"
                                    :height="heightVSelect"
                                    :items="grades"
                                    item-value="grade"
                                    item-text="grade"
                                    @change="updateRecord" 
                                    :readonly="disabled"
                                    :filled="disabled"                    
                                >
                                    <!-- <template v-slot:selection="{item}">
                                        {{ item.grade }}
                                    </template> -->
                                    <template v-slot:selection="{item}">
                                        <v-chip
                                            v-if="chipGrade"
                                            close
                                            @click:close="closeChip('mcourse')"
                                        >
                                            {{ item.grade }}
                                        </v-chip>
                                    </template>

                                    <template
                                        v-slot:item=data
                                    > 
                                        {{ data.item.grade}} : {{ data.item.scale }}
                                    </template> 
                                </v-select>
                            </v-card>    
                        </v-col>


                        <v-col cols="4" class="py-0">
                            <div>
                                <v-select
                                    v-model="studentRecord.mcomm"
                                    outlined
                                    dense
                                    hide-details
                                    small-chips
                                    deletable-chips
                                    :height="heightVSelect"
                                    :items="comments"
                                    item-value="id"
                                    item-text="id"
                                    @change="updateRecord"
                                    :readonly="disabled"
                                    :filled="disabled"
                                >
                                    <template v-slot:selection="{item}">
                                        <v-chip
                                            v-if="chipComment"
                                            close
                                            @click:close="closeChip('mcomm')"
                                        >
                                            {{ item.id }}
                                        </v-chip>
                                    </template>

                                    <!-- <template 
                                        v-slot:selection="{item}"                                       
                                    >
                                    <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                                    </template> -->

                                    <template
                                        v-slot:item="data"
                                    > 
                                        {{ data.item.id}} {{ data.item.detail }}
                                    </template>
                                </v-select>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>    
        </v-row>

        <v-row>
            <v-col class="pa-0 flex-grow-0">
                <v-card
                    flat
                    :width="width"
                >
                    <v-row 
                        class="pl-3 ml-0 mb-0"
                        id="label-form-class"
                    >
                        
                        <v-col cols="4" class="py-0"></v-col>

                        <v-col cols="4" class="py-0">
                            <v-card 
                                
                                flat
                                class=""
                                style="font-size:0.55rem; "
                            >
                                All Classes
                            </v-card>
                        </v-col>

                        <v-col cols="4" class="py-0">    
                            <v-card 
                               
                                flat 
                                style="font-size:0.55rem;"                           
                            >
                                Current Class
                            </v-card> 
                        </v-col>
                    </v-row>

                    <v-row class="pl-3 ml-0 mb-1">
                        <v-col cols="4" class="py-0 pl-0">
                            <v-card 
                                flat 
                                style="font-size:0.7rem"
                                class="mx-2"
                                
                            >
                                Possible Att.
                            </v-card>
                        </v-col>

                        <v-col cols="4" class="py-0 ">
                            <v-card 
                                flat
                            >
                                <v-text-field 
                                    v-model="attendanceAll"                               
                                    outlined
                                    hide-details
                                    dense
                                    style="font-size:0.7rem"
                                    type="number"
                                    @blur="changePossibleAttendance('All')"
                                    :disabled="!editTermReports"
                                    :filled="!editTermReports"
                                    :height="heightVSelect"
                                ></v-text-field>
                            </v-card>
                        </v-col>

                        <v-col cols="4" class="py-0">
                            <v-card 
                                flat                            
                            >
                                <v-text-field
                                    v-model="studentRecord.possible_attendance"
                                    outlined
                                    hide-details
                                    dense
                                    style="font-size:0.7rem"
                                    type="number"
                                    @blur="changePossibleAttendance(studentRecord.class_id)"
                                    :disabled="!editTermReports"
                                    :filled="!editTermReports"
                                    :height="heightVSelect"
                                ></v-text-field>
                            </v-card>  
                        </v-col>                     
                    </v-row>

                    <v-row class="pl-3 ml-0 mb-1">
                        <v-col cols="4" class="py-0 pl-0">
                            <v-card 
                                flat 
                                style="font-size:0.7rem"
                                class="mx-2"
                            >
                                School Reopens
                            </v-card>
                        </v-col>

                        <v-col cols="4" class="py-0">
                            <v-card 
                                flat
                            >                            
                                <date-picker 
                                    v-on:change-date="newTermDate($event,'All')"
                                    :disabled="!editTermReports" 
                                ></date-picker>                           
                            </v-card>
                        </v-col>

                        <v-col cols="4" class="py-0">
                            <v-card 
                                style="font-size:0.7rem" 
                                flat                            
                            >
                                <date-picker 
                                    v-on:change-date="newTermDate($event, studentRecord.class_id)"
                                    v-bind:date="studentRecord.new_term_beginning"
                                    :disabled="!editTermReports"
                                ></date-picker> 
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row class="pl-3 ml-0">
                        <v-col cols="4" class="py-0 pl-0">
                            <v-card 
                                flat 
                                style="font-size:0.7rem"
                                class="mx-2"
                            >
                                Change Class
                            </v-card>
                        </v-col>

                        <v-col cols="4" class="py-0">
                            <v-card 
                                flat
                            >
                                <v-select
                                    outlined
                                    hide-details
                                    dense
                                    :items="classes"
                                    class="change-class"
                                    style="font-size:0.6rem"
                                    v-model="studentRecord.class_id"
                                    @change="changeClass"
                                    :disabled="!editTermReports"
                                    :filled="!editTermReports"
                                    :height="heightVSelect"
                                ></v-select>
                            </v-card>
                        </v-col>

                        <v-col cols="4" class="py-0">
                            <v-card 
                                flat                            
                            >
                                <v-btn                                
                                    color="primary"
                                    x-small
                                    height="30"
                                    block
                                    @click="deleteRecord"
                                    :disabled="!editTermReports"                                
                                >
                                    Delete
                                </v-btn>
                            </v-card> 
                        </v-col>                      
                    </v-row>

                    <v-row class="pl-3 ml-0 mt-4 mb-2">
                        <v-col cols="6" class="py-0 pl-0">
                            <v-card 
                                flat 
                                style="font-size:1rem; font-weight:bold; color:green"
                                class="mx-2"
                            >
                                Grade: <span style="font-weight:normal; color:black">{{ grade }}</span>
                            </v-card>
                        </v-col>

                        <v-col cols="6" class="py-0 pl-0">
                            <v-card 
                                flat 
                                style="font-size:1rem; font-weight:bold; color:green"
                                class="mx-2"
                            >
                                Average: <span style="font-weight:normal; color:black">{{ average }}</span>
                                <span v-if="average" style="font-weight:normal; color:black">%</span>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
        </v-row>

        <v-row
            class="mt-2 mr-0 px-4"
        >
            <v-btn                
                color="primary"
                block
                small
                @click="nextClass"
            >
                Next Class
            </v-btn>
        </v-row>

        <v-row
            class="mt-3 mb-2 mr-0"
        >
            <v-card
                flat
                :width="width"
                class="d-flex justify-space-between pl-3"
            >
                <v-btn 
                    class="text-caption pa-2  white--text"
                    width="15"
                    height="30"                
                    color="primary"
                    @click="previousRecord"
                    :disabled="!pagination.prev_page"                
                >
                    <div class="d-flex flex-column"> 
                        <span>PREV</span>
                    </div>                                         
                </v-btn>
                
                <v-btn 
                    class="text-caption pa-2"
                    width="15"
                    height="30"
                    outlined
                    disabled                
                    text                
                >
                    <span class="blue--text">{{pagination.current_page}} of {{ pagination.last_page}}</span>                                                        
                </v-btn>
                
                <v-btn 
                    class="text-caption pa-2  white--text"
                    width="15"
                    height="30"
                    dark
                    color="primary"
                    @click="nextRecord"
                    :disabled="!pagination.next_page"
                >
                    <div class="d-flex flex-column">
                        <span>NEXT</span>
                    </div>                                         
                </v-btn>
            </v-card>    
        </v-row>

        <v-overlay
            absolute
            :value="overlay"
            color="secondary"
            opacity="0.6"
        >
            <v-expand-transition>
                <v-card
                    max-width="600"
                    class="mx-auto pa-6"
                    transition="scroll-y-transition"
                    v-show="expand"
                    light
                >
                    <v-card-title
                        class="mb-6"
                    >
                        <v-text-field
                            v-model="search"
                            label="Search for Student Record"
                            append-icon="mdi-magnify"
                            class="mx-4"
                            hide-details
                            single-line
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="table1Records"
                        :search="search"
                        fixed-header
                        height="40vh"
                        :options="options"
                        dense
                    >
                        <template
                            v-slot:[`item.actions`]="{ item }"
                        >
                            <v-btn
                                text
                                color="primary"
                                @click="viewRecord(item)"
                            >
                                View Record
                            </v-btn>
                        </template>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            outlined
                            @click="cancelSearch"
                        >
                            Cancel Search
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
        </v-overlay>

    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DatePicker from './DatePicker.vue';
export default {
    created(){
        this.initialize();
        //console.log(this.extraCurricularFields(1));
    },

    components: {        
        DatePicker,
    },

    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getSelectedStudentRecord',            
            academicYear: 'termReports/getAcademicYear',
            currentTerm: 'termReports/getCurrentTerm',
            formTeacherClass: 'termReports/getFormTeacherClass',
            codedComments: 'termReports/getCodedComments',
            table1Records: 'termReports/getTable1Records',
            pagination: 'termReports/getPagination',
            formClasses: 'termReports/getFormClassesList',
            admin: 'auth/getAdmin',
            table2Records: 'termReports/getEditViewTermReportsTable2Records',
            editTermReports: 'admin/getEditTermReports',
        }),

        studentName () {
            return this.studentRecord.first_name + ' ' + this.studentRecord.last_name;
        },

        classId () {
            return this.studentRecord.class_id;
        },

        recordSummary () {
            return [
                {title: 'Student\'s ID', model: 'student_id', select: false, disabled: true, section: 1},
                // {title: 'Possible Attendance', model: 'possible_attendance', select: false, disabled: true, section: 1},
                {title: 'Times Absent', model: 'times_absent', select: false, disabled: false, section: 1},
                {title: 'Times Late', model: 'times_late', select: true, disabled: false, section: 1},
            ]
        },

        termName () {
            let termName = '';
            if(this.currentTerm == 1) termName =  '1';
            if(this.currentTerm == 2) termName = '2';
            if(this.currentTerm == 3) termName = '3';
            return termName
        },

        average () {
            let total = 0, subjects = 0;
            this.table2Records.forEach(value => {
                subjects++;


                if(this.currentTerm == 1 && this.studentRecord.form_level == 6)
                total += value.course_mark

                else if(
                    this.currentTerm == 2 && (
                    this.studentRecord.form_level == 1 ||
                    this.studentRecord.form_level == 2 ||
                    this.studentRecord.form_level == 4))
                total += value.course_mark

                else if(
                    this.currentTerm == 2 &&
                    this.studentRecord.form_level > 4
                )
                total += value.exam_mark;

                else if(
                    this.currentTerm == 3 && this.studentRecord.form_level == 3
                ) 
                total += value.course_mark
                
                else{
                    total += value.exam_mark*0.75;
                    total += value.course_mark*0.25;
                } 
            })
            
            
            return subjects == 0 ? null : (total/subjects).toFixed(1);
        },

        grade () {
            if(this.average >= 75) return 'A';
            else if(this.average >= 65) return 'B';
            else if(this.average >= 55) return 'C';
            else if(this.average >= 50) return 'D';
            else if(this.average === 0 || this.average >0) return 'E'; 
            return null;
        }
        
        
    },
    watch: {
        studentRecord: {
            handler (val) {                
                // this.setPromotedToClasses();                
                this.setFormClasses();
                this.setDisabledFields();
                if(val.mcomm) this.chipComment = true;
                if(val.mcourse) this.chipGrade = true;
                // console.log(`Admin: ${this.admin}`)
                this.comments = this.codedComments
                
            }
        },

        // formClasses: {
        //     handler () {
        //         this.setFormClasses();
        //     }
        // },
    },

    data: () => ({
        academicPeriod: [
            { title: 'Year', value: '' },
            { title: 'Term', value: '' },
        ],
        grades: [            
            {grade: 'A', scale: '75 - 100'},
            {grade: 'B', scale: '65 - 74'},
            {grade: 'C', scale: '55 - 64'},
            {grade: 'D', scale: '50 - 54'},
            {grade: 'E', scale: '0 - 49'},
            {grade: 'Abs', scale: 'Abs'},
                     
        ],
        
        headers: [
            { text: 'Student ID', align: 'center', value: 'student_id', width: '75' },
            { text: 'First Name', align: 'left', value: 'first_name', width: '100' },
            { text: 'Last Name', align: 'left', value: 'last_name', width: '100' },
            { text: '', align: 'center', value: 'actions', sortable: false, width: '150' },
        ],
        options: {
            itemsPerPage: -1,
        },  
        codedComment: { id: null, comment: null },  
        religiousStudiesComments: [],
        disabled: false,
        disabledPromotedTo: false,
        expand: false,
        overlay: false,
        search: '',
        promotedToClasses: [],
        width: '330',  
        value: ' ', 
        rules: [],
        classes: [], 
        chipGrade: false,
        chipComment: false,
        comments: [],
        heightVSelect: 25,
        attendanceAll: '',
    }),

    methods: {
        ...mapActions({ 
            postTable1Record: 'termReports/postTable1Record',
            getTable2Records: 'termReports/getTable2Records', 
            setErrorResponse: 'termReports/setErrorResponse',  
            postChangeClass: 'admin/changeClass',
            postPossibleAttendance: 'admin/postPossibleAttendance',
            postNewTermBeginning: 'admin/postNewTermBeginning',          
        }),        
        ...mapMutations({         
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setOverlay: 'termReports/setEditViewTermReportsOverlay',
            setPagination: 'termReports/setPagination',           
            setLoading: 'termReports/setEditViewTermReportsLoading',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects', 
            setExpand: 'termReports/setEditViewTermReportsExpand',
            setBottomSheetModel: 'termReports/setBottomSheetModel',
            setBottomSheetTitle: 'termReports/setBottomSheetTitle',
            setCommentSheet: 'termReports/setCommentSheet',
            loading: 'termReports/setEditViewTermReportsLoading',
            setPossibleAttendance: 'admin/setPossibleAttendance',
            setSnackbar: 'admin/setSnackbar',
            setNewTermBeginning: 'admin/setNewTermBeginning',
            setChangeClassRecord: 'admin/setChangeClassRecord',
            setSelectedTable1Record: 'admin/setSelectedTable1Record',
            setDialogDelete: 'admin/setDialogDelete',            
        }),

        initialize(){            
            this.academicPeriod[0].value = this.academicYear;
            this.academicPeriod[1].value = this.termName;            
            //console.log(this.codedComments);            
        },

        setRecordSummary(){
            //
        },

        async updateRecord(){
            console.log('updating...');
            if(this.studentRecord.mcourse || this.studentRecord.mcomm) this.setChip();
            //console.log(this.studentRecord);
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            //this.studentRecord.rel_comment = this.codedComment.id; 
            console.log(this.studentRecord);
            try{
                await this.postTable1Record();
                this.promoteClass();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        },

        promoteClass () {
            let formLevel = this.studentRecord.form_level;
            if(formLevel != 3 && this.studentRecord.promoted_to){
                this.table1Records.forEach(record => {
                    record.promoted_to = this.studentRecord.promoted_to
                })
            }
        },
        
        searchStudent(){
            this.overlay = true;
            setTimeout(() => {
                this.expand = true;
            })
        },
        
        viewRecord(item){
            //console.log(item);
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
            let recordIndex = this.table1Records.indexOf(item);
            this.recordNavigation(++recordIndex);
        },
        cancelSearch(){
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
        },
        async recordNavigation(pagination){
            let response = null;
            this.setOverlay(true);
            this.setLoading(true);
            
            this.makePagination(pagination);
            this.setSelectedStudentRecord(this.table1Records[--pagination]);
            try {
                response = await this.getTable2Records();
                // console.log(response.data);                
                this.setTable2Records(response.data.table2_records);
                this.setStudentSubjects(response.data.student_subjects);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }            
            this.setOverlay(false);
            this.setLoading(false);           
        },
        makePagination(data){
            let prev_page = data;
            let current_page = data;
            let next_page = (current_page === this.pagination.last_page) ? null : ++data;
            prev_page = (current_page === 1) ? null : --prev_page;
            
            let pagination = {
                current_page: current_page,
                last_page: this.pagination.last_page,
                next_page: next_page,
                prev_page: prev_page,
            };
            this.setPagination(pagination);
        },

        async previousRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.prev_page);
            // console.log('previous');
        },

        nextRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.next_page);
            // console.log('next');
        },

        cardWidth (item, index ){
            if(index == 1) return '100px';
            if(item.type == 'select') return '100px'
            return '65px'
        },

        setDisabledFields () {

            if(this.formTeacherClass != this.studentRecord.class_id && !this.editTermReports){
                //console.log('Not the form Teacher');
                this.disabled = true;
                this.disabledPromotedTo = true;
                this.recordSummary.forEach(element => {
                    element.disabled = true;
                })                    
            }
            
        },

        setPromotedToClasses () {            
            this.promotedToClasses = this.formClasses.filter(value => {
                if(value.form_level == this.studentRecord.form_level + 1)
                return value;
            })
        },

        nextClass () {
            this.setOverlay(true);
            this.setExpand(true);
        },

        saveTeacherComment(){
            //console.log('saving...');
            //console.log(this.$refs.form.validate());
            this.updateRecord();
        },

        async changeClass () {
            this.setOverlay(true);
            this.loading(true);            
            this.setChangeClassRecord({
                student_id: this.studentRecord.student_id,
                class_id: this.studentRecord.class_id
            })
            try {
                await this.postChangeClass();
                this.setSnackbar({
                    text: `Class changed to ${this.studentRecord.class_id}`,
                    display: true,
                    color: 'primary',
                });               
                // console.log(response);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSnackbar({
                    text: `Error occured`,
                    display: true,
                    color: 'red',
                });              
            }            
            this.setOverlay(false);
            this.loading(false);
        },

        async changePossibleAttendance (formClass) {
            this.loading(true);
            this.setOverlay(true);
            // console.log(formClass);

            let snackbar = {};

            if(formClass == 'All'){
                this.studentRecord.possible_attendance = this.attendanceAll;
                this.setPossibleAttendance({
                    possible_attendance: this.attendanceAll,
                    class_id: null,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                });
                snackbar.text = `Possible attendance set to ${this.studentRecord.possible_attendance} for all classes.`
            }
            else{
                this.setPossibleAttendance({
                    possible_attendance: this.studentRecord.possible_attendance,
                    class_id: this.studentRecord.class_id,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                })
                snackbar.text = `Possible attendance set to ${this.studentRecord.possible_attendance} for ${this.studentRecord.class_id}.`
            }

            try {
                let response = await this.postPossibleAttendance();
                snackbar.color = 'primary';
                snackbar.display = true;
                this.setSnackbar(snackbar);  
                console.log(response);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                snackbar.color= 'red';
                snackbar.display = true;
                snackbar.text = 'An Error Has Occured';
                this.setSnackbar(snackbar);
            }
            
            this.setOverlay(false);
            this.loading(false);
        },

        async newTermDate (e, formClass) {
            this.setOverlay(true);
            this.loading(true);
            this.studentRecord.new_term_beginning = e;

            let snackbar = {};

            if(formClass == 'All'){
                this.setNewTermBeginning({
                    new_term_beginning: e,
                    class_id: null,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                });
                snackbar.text = `New Term Beginning set to ${e} for all classes.`
            }
            else{
                this.setNewTermBeginning({
                    new_term_beginning: e,
                    class_id: this.studentRecord.class_id,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                });
                snackbar.text = `New Term Beginning set to ${e} for ${formClass}.`
            }
            // console.log(`Date: ${e}, class: ${formClass}`);

            try {
                await this.postNewTermBeginning();
                this.$emit('update-records');
                snackbar.color = 'primary';
                snackbar.display = true;
                this.setSnackbar(snackbar);  
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                snackbar.color= 'red';
                snackbar.display = true;
                snackbar.text = 'An Error Has Occured';
            }

            this.setOverlay(false);
            this.loading(false);
            
        },

        deleteRecord () {
            // console.log(this.table2Records);
            this.setSelectedTable1Record(this.studentRecord);
            if(this.table2Records.length != 0){
                this.setDialogDelete({
                    display: true,
                    text: 'Cannot delete record with existing subject records. Please delete all subject records first.',
                    cancel: false,
                    deleteSubject: false,
                    deleteStudent: false,
                    ok: true
                })
            }
            else{
                console.log(this.studentRecord);
                this.setDialogDelete({
                    display: true,
                    text: `Delete ${this.studentRecord.first_name} ${this.studentRecord.last_name} ?`,
                    cancel: true,
                    deleteSubject: false,
                    deleteStudent: true,
                    ok: false
                })
            }
        },

        openBottomSheet(model){
            this.setBottomSheetModel(model); 
            if(model == 'comments'){
                this.setBottomSheetTitle('Form Teacher Comments');
            }
            else{
                this.setBottomSheetTitle('General Remarks: Conduct');
            }
            if(!this.disabled) this.setCommentSheet(true);
            //console.log(`sheet: ${ this.sheet }`);
        },

        setChip () {
            Object.keys(this.studentRecord).forEach(key  => {
                if(key == 'mcomm' && this.studentRecord[key])
                this.chipComment = true;
                if(key == 'mcourse' && this.studentRecord[key])
                this.chipGrade = true;
            })
        },

        closeChip (model) {
            switch (model) {
                case 'mcomm':
                    this.studentRecord.mcomm = null;
                    this.chipComment = false;
                    break;
                case 'mcourse':
                    this.studentRecord.mcourse = null;
                    this.chipGrade = false;
                    break;
            }
            this.updateRecord();
        },

        setFormClasses () {   
            this.formClasses.forEach(value => {
                this.classes.push(value.class_id);
            });
        },

        
    },

   
}
</script>

<style scoped>
    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    ::v-deep .v-list--dense .v-list-item{
        min-height: 32px;
    }

    .v-btn__content{
        font-size: 0.9em;
    }

    ::v-deep .v-input__append-inner{
        margin-top: 0;
        padding-left: 0 !important;
    }

    ::v-deep .v-select__selections{
        height: 30px;
        /*padding: 0 !important;*/
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    ::v-deep .v-select__selection--comma{
        margin: 0 4px 4px 0;
    }

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    ::v-deep .v-chip.v-size--default{
        height: 20px;
        font-size: 11px;
        margin-top: 4px;
    }

    /* ::v-deep .times_late .v-chip.v-size--default{
        margin-top: 0;
    } */

    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
            min-height: 8px;
        }

    ::v-deep .v-select.v-select--chips .v-select__selections{
        min-height: 8px;
    }

    ::v-deep .v-application--is-ltr .v-text-field .v-input__append-inner{
        padding-left: 0;
    }

    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 2px;
    }

    /* ::v-deep .v-application--is-ltr .v-text-field .v-input__append-inner{
        padding-left: 0;
    }     */

    ::v-deep .v-label.v-label--active.theme--light{
        left: 10px !important;
    }

    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small.v-input--dense .v-select__selections{
        min-height: 20px;
    }

    ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details{
        margin-bottom: 0;
        padding-right: 0;
        font-size: 0.5rem;
    }

    ::v-deep .v-counter{
        font-size: 0.6rem;
    }

    ::v-deep .v-text-field__slot textarea{
        padding-left: 4px;
    }
    
    ::v-deep .v-select__selection{
        width: 100%;
        text-align: center;
    }

    ::v-deep .v-textarea textarea{
        line-height: 1.1rem;
    }

</style>